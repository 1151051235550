
export default function extractPoiLocationFromString(p_poi) {
    if (!p_poi || !p_poi.poi_location) {
        console.warn('Invalid POI:', p_poi);
        return null;
    }

    let poiLocation = p_poi.poi_location;
    
    if (typeof poiLocation === 'string') {
        let parenthesesRegExp = /\(([^)]+)\)/;
        let match = parenthesesRegExp.exec(poiLocation);
        if (match && match[1]) {
            poiLocation = String(match[1]).split(" ", 3);
        } else {
            console.warn('Invalid POI string format:', poiLocation);
            return null;
        }
    } else if (typeof poiLocation === 'object' && "lat" in poiLocation) {
        return poiLocation;
    } else {
        console.warn('Invalid POI object format:', poiLocation);
        return null;
    }

    return { lat: JSON.parse(poiLocation[1]), lng: JSON.parse(poiLocation[0]) };
}




export function convertPoiLocationToString(poiLocation){

    if('lng' in poiLocation &&  'lat' in poiLocation){
        return `POINT Z (${poiLocation.lng} ${poiLocation.lat} 65)`;
    }else{
        return "";
    }
}


export function convertPoiLocationObjectToArray(poiLocation){

    if('lng' in poiLocation &&  'lat' in poiLocation){
        return [poiLocation.lng,poiLocation.lat, 65];
    }else{
        return [];
    }
}