import axios from "axios";
import { serverHost } from '../redux/reducers/reducer';
import common_types from "../common_files/common_types";
import {stringify} from 'flatted';
// objectToSend
// {
//     mission_type :'',
//     mission:''
// }

export default  async function OpenTourMission(p_mission,p_user_id){

    const objectToSend =
    {
        mission_type :common_types.mission_type_enum.MISSION_TYPE_SHUTTLE,
        mission:p_mission,
        user_id:p_user_id
    }

    const json = stringify(objectToSend);
    const server =serverHost();

    try {
        let missionResponse = await axios.post(server +'/passenger/openShuttleMission',{data:json});
            if(missionResponse.status === 200){
                return missionResponse.data;
            }
            return 'error';
    } catch (error) {
        console.log("error While trying to OpenMission: " ,error);
        return 'error';
    }

}