import React from 'react';
import axios from 'axios';
import { serverHost } from '../redux/reducers/reducer';


async function approveDrivingRequest(p_user_id,p_poiId,p_isPickUp,p_connectionErrorfunction) {
    try {
        
        const response = await axios.post(serverHost() + `/transportation/confirmDriving`, { 
            user_id: p_user_id,
            poiId:p_poiId,
            isPickUp:p_isPickUp 
        });

        if (!response) {
            return;
        }
        if(response.status ===200){
            return response.data;
        }
        return ;
    } catch (error) {
        console.error('confirmPickup error!', error);
        p_connectionErrorfunction();
        return 'error';
    }

}

export default approveDrivingRequest