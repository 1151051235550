import { serverHost} from '../redux/reducers/reducer';
import axios from 'axios';

export default async function closeReservationRequest(p_user_id){
    const server =serverHost();
    try {
        const response = await axios.post(server + `/transportation/releaseCart`, { 
            user_id:p_user_id ,err:false
        });

        if(response.status === 200){
            return response.data;
        }else{
            console.log("error While trying to closeReservationRequest");
            return 'error';
        }
    } catch (error) {
        console.log("error While trying to closeReservationRequest: " ,error);
        return 'error';
    }

}