import { serverHost} from '../redux/reducers/reducer';
import axios from 'axios';




export default async function GetUserDetails(p_user_id){
   
    const user_key_reservation = localStorage.getItem('user_key_reservation');
    const server =serverHost();
    try {
        const response = await axios.get(server +`/passenger/userdetails`,{
            params: {
                user_id: p_user_id,
                userKeyReservation: user_key_reservation,
                userTimeout: true
            }
        });
        if(response.status === 200){
            return response.data;
        }else{
            console.log("error While trying to get user details");
            return 'error';
        }
    } catch (error) {
        console.log("error While trying to  get user details: " ,error);
        return 'error';
    }
   
}
        