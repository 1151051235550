import { AppText } from "../components/multiLanguage/multiLanguage";
    
const setDefaultPosition =({
    language,
    mapData,
    setMapIconLabelText,
    setLocationFuntion,
    setPickUpFromText,
    p_set_only_position
})=>{

    if(!mapData || !mapData.data){
        console.log("no map data " ,mapData);
        return
    };

    const defaultPoint = mapData.data[`map_default_point`];
    let latitude = defaultPoint[0];
    let longitude = defaultPoint[1];
    
    if(latitude && longitude){
        setLocationFuntion({lat:latitude,lng:longitude});

        //p_set_only_position=true => set only the position , when app loaded , give the first position(for icon)
        if(!p_set_only_position){
            setMapIconLabelText(AppText.map_label_massage.default[language]);
            setPickUpFromText(AppText.poiContainer.default[language]);
        }
    }
}

    export default setDefaultPosition;