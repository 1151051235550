import { serverHost} from '../redux/reducers/reducer';
import axios from 'axios';

export default async function getUserStatus(p_user_id,p_connectionErrorfunction){
    const server =serverHost();
    try {
        const response = await axios.get(server +`/passenger/status`,{
            params: {
                user_id: p_user_id,
            }
        });
        if(response.status === 200){
            return response.data;
        }else{
            console.log("error While trying to get user details");
            return 'error';
        }
    } catch (error) {
        p_connectionErrorfunction();
        console.log("error While trying to  get user details: " ,error);
        return 'error';
    }
}