import React from 'react';
import GeneralButton ,{themeOptions} from '../Buttons/carteavButton/generalCarteavButton';

function ErrorFallback({ error, resetErrorBoundary }) {
  const refreshPage = (e) => {
    e.preventDefault();
    window.location.reload();
  };
  return (
    <div style={{padding: '1rem',gap:'10px', border: '1px solid #e5e7eb', borderRadius: '0.5rem' ,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
      <p>Oops! Something went wrong:</p>
      <pre style={{ color: 'red', whiteSpace: 'pre-wrap' }}>{error.message}</pre>
      <GeneralButton
        id="error-refresh-button" 
        onClick={refreshPage} 
        text={"Refresh"}
        addedStyle={{width: "100px"}}
        theme={themeOptions.BLACK}
    />
    </div>
  );
}

export default ErrorFallback;