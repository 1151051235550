import axios from 'axios'
import { serverHost } from '../redux/reducers/reducer';


export default async function newDestinationReqForCart(p_user_id,p_locationStr,p_dest_name,p_connectionErrorfunction) {
  
    const server =serverHost();
    let sendObj = {
        user_id: p_user_id,
        destination: p_locationStr,
        destinationName: p_dest_name,
        user_key_reservation:localStorage.getItem('user_key_reservation'),
    };

    try {
        const response = await axios.post(server +'/transportation/newDestinationReqForCart', sendObj);
      
        if(response.status === 200){
            return response.data;
        }else{
            console.log("error While newDestinationReq");
            return 'error';
        }
    } catch (error) {
        console.log("error While newDestinationReq" ,error);
        p_connectionErrorfunction();
        return 'error';
    }
}

