import { useEffect, useRef ,useState } from 'react';
import getUserStatus from '../services/getUserStatus';

const useSocketForReservationData = ({socket ,user_id ,reservation, onCloseReservation, setReservation, setCartStatus, handleCartAvailabilityDataFromServer ,connectionError }) => {
  
  const reconnectIntervalRef = useRef(null);
  const timeoutIntervalRef = useRef(null);


  const handleUserStatus =(userStatus) => {
    // console.log('Fetching reservation status... ' ,userStatus);
    // {
    //   reservation:reservation || null,
    //   cartStatus:cartStatus || null,
    //   cartAvailability:cartAvailability 
    // }

     // get user status successfully
     if (
      typeof userStatus === 'object' &&
      userStatus.reservation !== undefined &&
      userStatus.cartStatus !== undefined
    ) {
      const cartAvailability = userStatus.cartAvailability;

      // Use functional updates to conditionally update state based on previous state
      setReservation(prevReservation => {
        if (prevReservation !== userStatus.reservation) {
          if(userStatus.reservation == null){
            console.log('Reservation closed');
            handleCartAvailabilityDataFromServer(cartAvailability);
            onCloseReservation();
          }
          return userStatus.reservation; 
        } else {
          if(prevReservation == null){
            // no reservation 
            handleCartAvailabilityDataFromServer(cartAvailability);

          }
          return prevReservation;
        }
      });
  
      setCartStatus(prevCartStatus => {
        if (prevCartStatus !== userStatus.cartStatus) {
          return userStatus.cartStatus;
        } else {
          return prevCartStatus; 
        }
      });
  
      // Update cart availability
      console.log("reservation updated " ,reservation);
      if (reservation == null) {
      
      }
    }
  }
  const getUserLiveStatus = async () => {
 
    // Fetch user status 
    const userStatus = await getUserStatus(user_id , connectionError);
    handleUserStatus(userStatus);
  }


  const startIntervalForGetUserStatus = () => {
    if (reconnectIntervalRef.current === null) {
        const intervalId = setInterval(() => {
          getUserLiveStatus();
        }, 1000);
        reconnectIntervalRef.current = intervalId;
    }
  }
  
  const stopIntervalForGetUserStatus = () => {
  
   if(reconnectIntervalRef.current !== null) {
    clearInterval(reconnectIntervalRef.current);
    reconnectIntervalRef.current =null
    }
  }

  const startOrClearTimeoutToGetUserStatusIfNoSocketsEventsArrived = () => {
    if(timeoutIntervalRef.current === null){
      const timeout_id = setTimeout(() =>{
        startIntervalForGetUserStatus();
      },3000);
      timeoutIntervalRef.current = timeout_id;
    }else{
      // console.log("clearTimeout")
      clearTimeout(timeoutIntervalRef.current);
      timeoutIntervalRef.current = null;
      stopIntervalForGetUserStatus();
    }

  }


  useEffect(() => {
    if(socket){
      function onConnect() {
        socket.emit('user_id', user_id);
        if(socket.recovered){
          getUserLiveStatus();
        }

        // if interval is alive then we need to stop it
        stopIntervalForGetUserStatus();
      }
  
      function onDisconnect() {
        // get the user status from server by http in case of socket disconnect
        startIntervalForGetUserStatus();
      }
  
      const onCartStatusEvent =(p_cartStatus)=>{
        reservation && setCartStatus(p_cartStatus);
        startOrClearTimeoutToGetUserStatusIfNoSocketsEventsArrived();
      }

      const onReservationStatusEvent =(p_reservationStatus)=>{
        if(!reservation) return;
        setReservation(p_reservationStatus);
        startOrClearTimeoutToGetUserStatusIfNoSocketsEventsArrived();
      };


      const onCartAvailabilityEvent =(p_cartAvailability)=>{
        if (reservation == null) {
          handleCartAvailabilityDataFromServer(p_cartAvailability);
        }
        startOrClearTimeoutToGetUserStatusIfNoSocketsEventsArrived();
      };
      
      const onUserInUseRelease =()=>{
        onCloseReservation();
      }

      socket.on('closeReservation', () => {
        onCloseReservation();
    });
    

      socket.on('connect', onConnect);
      socket.on('cartStatusToUser', onCartStatusEvent);
      socket.on('disconnect', onDisconnect);
      socket.on('reservationUpdate', onReservationStatusEvent);
      socket.on('cartsAvailability',onCartAvailabilityEvent);
      socket.on('userInUseRelease',onUserInUseRelease);
  
      return () => {
        socket.off('connect', onConnect);
        socket.off('disconnect', onDisconnect);
        socket.off('cartStatusToUser', onCartStatusEvent);
        socket.off('reservationUpdate', onReservationStatusEvent);
        socket.off('cartsAvailability',onCartAvailabilityEvent);
        socket.off('userInUseRelease',onUserInUseRelease);
        socket.off('closeReservation');
      };
    }
   
  }, [socket , reservation]);



  // useEffect(() => {

    
  //   if (socket) {
  //     socket.on('reservationUpdate', (p_reservation) => {
  //       console.log("SOCKET GOT RESERVATION STATUS")
  //       setReservation(p_reservation);
  //     });




  //     socket.on('cartStatusToUser', (p_cartStatus) => {
  //       reservation && setCartStatus(p_cartStatus);
  //     });

  //     socket.on('cartsAvailability', (p_cartsAvailabilityResponse) => {
  //       if (reservation == null) {
  //         handleCartAvailabilityDataFromServer(p_cartsAvailabilityResponse);
  //       }
  //     });

  //     socket.on('disconnect', () => {
  //       console.log('disconnect ');
  //       if (reconnectIntervalRef.current === null) {
  //         const intervalId = setInterval(() => {
  //           getUserLiveStatus();
  //         }, 1000);
  //         reconnectIntervalRef.current = intervalId;
  //         console.log('Interval started', reconnectIntervalRef.current);
  //       }
  //     });

  //     socket.on('connect', () => {
  //       console.log('Socket connected UseRESERVATION');
  //       if(reconnectIntervalRef.current !== null) {
  //         console.log('stopping interval');
  //       clearInterval(reconnectIntervalRef.current);
  //       reconnectIntervalRef.current =null
  //       }
  //     });
    

  //   return () => {
  //     if (socket) {
  //       socket.off('reservationUpdate');
  //       socket.off('closeReservation');
  //       socket.off('cartsAvailability');
  //       socket.off('cartStatusToUser');
  //       // clearInterval(reconnectIntervalRef.current);
  //     }
  //   };
  // }

  // }, [socket , reservation ]);


  return null;
};

export default useSocketForReservationData;
