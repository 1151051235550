import { serverHost } from '../redux/reducers/reducer';
import axios from 'axios';

let configData = null;

export default async function GetConfigJsonRegister() {
    if (configData) {
        return configData; // Return cached config data if it already exists
    }
    
    const server = serverHost();
    
    try {
        const response = await axios.post((server + '/utils/get_config_json_register'));
        if (response.status === 200) {
            configData = response.data;
            return configData;
        } else {
            console.log("Error while trying to get config JSON");
            return 'error';
        }
    } catch (error) {
        console.log("Error while trying to get config JSON: ", error);
        return 'error';
    }
}

