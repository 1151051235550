import renderCustomAlert from "../components/customAlert/customAlert";
import { AppText } from "../components/multiLanguage/multiLanguage";
import newDestinationReqForCart from "../services/newDestinationForCart";
import {cartInPointAlert, somethingWentWrongAlert} from "./renderAlerts/calcPathAlerts";

const confirmDestination = async ({
    p_poi_location,
    p_poi_name,
    setReservation,
    setUserFarFromRoad,
    setSearchPath,
    language,
    connectionError,
    user_id,
}) => {


    setSearchPath(true);

    try {
        const confirmDestinationResponse = await newDestinationReqForCart(user_id, p_poi_location, p_poi_name, connectionError);
        
        setSearchPath(false);
        
        if (confirmDestinationResponse) {
            const data = confirmDestinationResponse.reservationDetails;
            if (data) {
                if (data.resultCode === 'cart_in_point' ) {
                    cartInPointAlert(language)
                } else if (data.resultCode === 'destination far from any roads' ) {
                    setUserFarFromRoad(true);
                } else if (data.resultCode === 'path_found') {
                    setReservation(data.reservationDetails);
                    return true;
                }
            }else if (!data || data.resultCode === 'no_user') {
                somethingWentWrongAlert(language);
            }
        }else{
            somethingWentWrongAlert(language);
        }
        return false;
    }catch (error) {
        console.error('Error:', error);
        setSearchPath(false);
        somethingWentWrongAlert(language);
    }

    return false;
};

export default confirmDestination;
