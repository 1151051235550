
import { serverHost} from '../redux/reducers/reducer';
import axios from 'axios';

// PAUSE || DRIVE



export default async function postErrorToServer(error, info) {
    const server =serverHost();
    console.log(server);
   
    try {
        const data = {
            error:error,
            info:info,
        }
        const response = await axios.post(server +`/user/errorReport`, data);
        if(response.status === 204){
            return true;
        }else{
            console.log("error post error report ");
            return false;
        }
    } catch (error) {
        console.log("error post error report " ,error);
        return false;
    }
}
