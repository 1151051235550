
// Function to periodically check carts availability
const checkCartsAvailabilityFunction = async ({
    setIsCartAvailableForReservation,
    setCartWillBeAvailableIn_seconds,
    cartsAvailabiltyData,
}) => {


    if (Array.isArray(cartsAvailabiltyData)) {

        let estimate = cartsAvailabiltyData[1];
        setCartWillBeAvailableIn_seconds(estimate)

        //-2 - service closed
        //-1 no carts available 
        // >0 - eta 
    }

    // Update cart availability state based on cartsAvailabiltyData
    setIsCartAvailableForReservation(prevState => {
        if(prevState == null)return cartsAvailabiltyData === 'ok';
        if (prevState === true && cartsAvailabiltyData === 'ok') {
            return prevState;
        } else if (prevState === false && cartsAvailabiltyData === 'ok') {
            return true;
        } else if (prevState === true && Array.isArray(cartsAvailabiltyData)) {
            return false;
        }
        return prevState;
    });

  
}

export default checkCartsAvailabilityFunction;
