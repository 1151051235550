// src/services/getNonStopAreaMapping.js
import axios from 'axios';
import { serverHost} from '../redux/reducers/reducer';

const getNonStopAreaMapping = async () => {
    const server =serverHost();

    try {
        const response = await axios.post(server+'/mapping/getNonStopAreaMapping');
        return response.data;
    } catch (error) {
        console.error('Error fetching non-stop area mapping:', error);
        throw error;
    }
};

export default getNonStopAreaMapping;
