import { AppText } from "../components/multiLanguage/multiLanguage";
import isUserInsideMapbounderies from "./isUserInsideMapbounderies";

// calculates the user's location based on the provided position object
const calcUserLocation = ({
    language,
    lat,
    lon,
    setMapIconLabelText,
    setUserCurrentLocation,
    triggerSetDefaultPosition,
    mapData

}) => {
    
    const isUserInsideMapbounderiesResponse = isUserInsideMapbounderies(mapData,lat,lon);
    
    if(isUserInsideMapbounderiesResponse)
    {
        setMapIconLabelText(AppText.map_label_massage.gps[language]);
        setUserCurrentLocation({lat:lat,lng:lon});
    }
    else
    {
        // If the user is outside the map boundaries, set a default position
        triggerSetDefaultPosition();
    }
}

export default calcUserLocation;