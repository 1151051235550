
export const serverHost = function() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'|| process.env.NODE_ENV === 'test') {
        return "http://localhost:8080" ;
    } else {
        let server =`https://${window.location.hostname}`;
        return server;
    }
};

