import { AppText } from "../components/multiLanguage/multiLanguage";
import approveDrivingRequest from "../services/approveDestinantionRequest";

const approvePickupUser =async ({
    user_id,
    connectionError,
    clearAutoReleaseEtaInterval,
    setSecondaryTopBarMessageText,
    language,
    setActionSent

})=>{

    setActionSent(true);

    clearAutoReleaseEtaInterval();

    const confirmPickupResponse = await approveDrivingRequest(user_id,null,true,connectionError);
    if (confirmPickupResponse === 'cart_is_offline'){
        setActionSent(false);
        setSecondaryTopBarMessageText(AppText.messages.cart_is_offline.title[language]);
        return;

    }else if(confirmPickupResponse ==='no_res'){
        // back to user order page
        // RESET_CONTEXT_STATES();
        setActionSent(false);

        return;
        
    }
    
    
    if(confirmPickupResponse ==='ok'){
        // cart start driving , should get cart status ! 
        // setApproveDrive(true);

        setSecondaryTopBarMessageText(AppText.driving.cart_on_the_way[language]);
        // reloadCartStatusInterval();
    }else{
        setSecondaryTopBarMessageText(AppText.userOrder.something_went_wrong[language]);
    }



    
}

export default approvePickupUser;

