/**
 * Checks if a specified key exists in any of the objects within an array.
 *
 * @param {Array<Object>} arr - The array of objects to check.
 * @param {string} key - The key to look for in the objects.
 * @returns {boolean} - Returns true if the key exists in any of the objects, otherwise false.
 *
 * @example
 * const arrayOfObjects = [{'environment': 'park_environment'}, {'velocity': '2.4'}];
 * const keyToCheck = 'velocity';
 * const result = isKeyExistWithinArrayOfObjects(arrayOfObjects, keyToCheck); // result is true
 *
 * @example
 * const arrayOfObjects = [{'environment': 'park_environment'}, {'velocity': '2.4'}];
 * const keyToCheck = 'altitude';
 * const result = isKeyExistWithinArrayOfObjects(arrayOfObjects, keyToCheck); // result is false
 */
export default function isKeyExistWithinArrayOfObjects(arr, key) {
    if (Array.isArray(arr)) {
        return arr.some(obj => key in obj);
    }
    return false;
}
