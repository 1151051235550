import { languageMap } from '../multiLanguage/multiLanguage';
import './customAlert.css';

// Object to store visible alerts
const visibleAlerts = {};

const renderCustomAlert = ({ id, title, bodyText, language = 'en', type = "info", duration = 3000 }) => {
    return new Promise((resolve, reject) => {

        const existingAlerts = document.querySelectorAll('.custom-alert');
        const direction = languageMap[language].dir;
        // Check if an alert with the specified ID is already visible
        if (id && visibleAlerts[id] || existingAlerts.length > 2) {
            return resolve();
        }

        const alertContainer = document.createElement('div');
        alertContainer.classList.add('custom-alert');
        alertContainer.classList.add(type);
        if (direction == 'rtl') {
            alertContainer.style.alignItems = 'flex-end';
        }

        alertContainer.innerHTML = `
            <div class="alert-header">${title}</div>
            <div class="alert-bodyText">${bodyText}</div>
            <div class="progress-bar"></div> <!-- Progress bar -->
        `;


          // Create and append the close button
          const closeButton = document.createElement('button');
          closeButton.classList.add('close-btn');
          closeButton.innerHTML = '&times;';
          alertContainer.appendChild(closeButton);
  
          // Apply styles to the close button based on direction
          if (direction === 'rtl') {
              closeButton.style.left = '10px'; // Adjust left position for RTL
          } else {
              closeButton.style.right = '10px'; // Default right position for LTR
          }

        // Calculate the position of the alert
        const calculatePosition = () => {
            let topPosition = 0;

            if (existingAlerts.length > 0) {
                existingAlerts.forEach((existingAlert) => {
                    topPosition += existingAlert.offsetHeight + 8; // Add some margin between alerts
                });
            }

            return topPosition;
        };

        const topPosition = calculatePosition();
        if (topPosition > 0) {
            alertContainer.style.marginTop = topPosition + 'px';
        }

        document.body.appendChild(alertContainer);

        // Calculate progress bar animation duration
        const progressBar = alertContainer.querySelector('.progress-bar');
        progressBar.style.animationDuration = duration / 1000 + 's';

        // Close button functionality
        closeButton.addEventListener('click', () => {
            alertContainer.remove();
            if (id) {
                delete visibleAlerts[id];
            }
            resolve();
        });

        // After the specified duration, remove the alert
        setTimeout(() => {
            alertContainer.classList.add('hide');
            alertContainer.addEventListener('animationend', (e) => {
                e.preventDefault();
                alertContainer.remove();
                if (id) {
                    delete visibleAlerts[id];
                }
                resolve();
            });
        }, duration);

        // Add the alert to the visible alerts object
        if (id) {
            visibleAlerts[id] = true;
        }
    });
};

export default renderCustomAlert;
