import { serverHost} from '../redux/reducers/reducer';
import axios from 'axios';


export default async function getAllPoi(p_userId){
    const server =serverHost();
    try {
        const response = await axios.get(server +'/passenger/get_points_of_interests',{
            params: {
                is_load_pics: true,
                user_id: p_userId

            }
        });
        

        if(response.status === 200){
            return response.data
        }else{
            console.log("ERROR getPoi");
            return 'error';
        }
    } catch (error) {
        console.log("ERROR" ,error);
        return 'error';
    }

}
