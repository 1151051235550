import React from 'react';
import App from './App/App';
import ReactDOM from "react-dom/client";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './index.css';

// Set translate="no" attribute on the HTML element
document.documentElement.setAttribute("translate", "no");


// // Get the current version from the .env file
// const currentVersion = process.env.REACT_APP_VERSION;

const root = ReactDOM.createRoot(document.getElementById("root")); 
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


// serviceWorker.register(configuration);
serviceWorkerRegistration.register();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
