export function buildFakePoi(p_poi_id,p_location,p_name){
    return {
        poi_id:p_poi_id ,
        poi_name: p_name,
        poi_category_id:null,
        poi_location:p_location ,
        poi_pic_path: null,
        img_str:null,
      }
}
