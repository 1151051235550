import { createContext, useEffect, useState } from "react";
import GetMapData from "../../services/mapData";
import GetConfigJson from "../../services/getConfigJson";
import { useHistory, useLocation } from "react-router-dom";
import React from 'react';

export const sharedDataContext = createContext({});

export const DataProvider = ({ children }) => {
    const [mapData, setMapData] = useState(null);
    const history = useHistory();
    const location = useLocation();
    const [imageSrc, setImageSrc] = useState(null);
    const [appHeight, setAppHeight] = useState(window.innerHeight);
    const [configData, setConfigData] = useState(null);

    // Handle window resize for app height
    useEffect(() => {
        const handleResize = () => {
            setAppHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Fetch and set map data
    useEffect(() => {
        const fetchMapData = async () => {
            const mapResponse = await GetMapData();
            if (mapResponse !== 'err') {
                setMapData(mapResponse);
                if (mapResponse.hasOwnProperty('picture')) {
                    const map_image = mapResponse.picture;
                    try {
                        const { default: image } = await import(`../../assets${map_image}`);
                        setImageSrc(image);
                    } catch (error) {
                        console.error('Error loading image:', error);
                    }
                }
            }
        };

        const currentPath = location.pathname;
        if (mapData == null && (currentPath.includes('user') || currentPath.includes('mapping'))) { 
            fetchMapData();
        }
    }, [location]);

    // Fetch and set config data
    useEffect(() => {
        const fetchConfigData = async () => {
            const configResponse = await GetConfigJson();
            if (configResponse !== 'error') {
                setConfigData(configResponse);
            }
        };

        fetchConfigData();
    }, []);

    const getConfigValueRegister = (key) => {
        if (!configData) {
            console.log("Config data is not loaded yet.");
            return null;
        }
    
    
        // Find the object where the "name" matches the key
        const param = configData.find(param => param.name === key);
        if (param) {
            return param.value;  // Return the value if found
        } else {
            console.log(`Key '${key}' not found in Site params.`);
            return null;
        }
    };
    

    return (
        <sharedDataContext.Provider
            value={{
                mapData,
                history,
                imageSrc,
                appHeight,
                getConfigValueRegister,  // Exporting getConfigValue function
            }}
        >
            {children}
        </sharedDataContext.Provider>
    );
}

export default sharedDataContext;
