import { serverHost} from '../redux/reducers/reducer';
import axios from 'axios';

export default async function GetLanguage(){
    const server =serverHost();
    
    try {
        const response = await axios.post((server +'/utils/get_language'));
        if(response.status === 200){
            return response.data;
        }else{
            console.log("error While trying to get language");
            return 'error';
        }
    } catch (error) {
        console.log("error While trying to get language: " ,error);
        return 'error';
    }

}