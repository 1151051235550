import { createContext, useEffect, useState } from "react";
import GetLanguage from "../../services/getLanguage";
import React from 'react';

export const sharedDataContext = createContext({});

export const LanguageAndServerProvider = ({ children  }) => {

    let [language, setLanguage] = useState('en');

    useEffect( ()=>{


        const getLanguage= async ()=>{
            const langResponse = await GetLanguage(); 
            if(langResponse !== 'error'){
                setLanguage(langResponse)
            }else{
                setLanguage('en');
            }
        }
        getLanguage();

    },[])

        return (
            <sharedDataContext.Provider 
                value={{ 
                    language
                }}
            >
                {children}
            </sharedDataContext.Provider>
        )
}

export default sharedDataContext;
