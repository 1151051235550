import { useState, useEffect } from 'react';
import getAllPoi from "../services/getPoi";
import { AppText } from '../components/multiLanguage/multiLanguage';

const filterCategoriesByExistingPois = (poisObject, categories,user_id) => {
    if(!Array.isArray(categories))return ;
    const filteredCategories = [];
    for(const categoryId of Object.keys(poisObject)) {

        const categortObject =categories.find(c => categoryId === c.category_id);
        if(!categortObject){
            // create a new category
            if(categoryId === user_id) {
                // saved points category
                const fakeCategoryObject ={
                    category_id:categoryId, 
                    category_name:'saved_points',
                    category_icon: {}
                }
                filteredCategories.unshift(fakeCategoryObject);
            }
        }else{
            filteredCategories.push(categortObject);
        }
    }

    return filteredCategories;
}

const createCategoriesObjectByID = (categories,user_id,language) => {

    if (categories && Array.isArray(categories)) {
        const categoryObject = {};
        for (const category of categories) {
            categoryObject[category.category_id] = category.category_name;
        }
        categoryObject['history'] = AppText.pois.categories.history[language];
        categoryObject[user_id]=AppText.pois.categories.saved[language];
        return categoryObject;
    }
}

const implementPOIsObjectByCategory = (pois, history) => {
    const poisObj = {};
    if (Array.isArray(pois)) {
        if (history) {
            poisObj['history'] = history;
        }
        for (const poi of pois) {
            if (poisObj[poi.poi_category_id] && Array.isArray(poisObj[poi.poi_category_id])) {
                poisObj[poi.poi_category_id].push(poi);
            } else {
                poisObj[poi.poi_category_id] = [poi];
            }
        }
    }
    return poisObj;
}

const usePoisAndCategories = ({ user_id ,language}) => {
    const [poisData, setPoisData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [savedTours, setSavedTours] = useState([]);

    const getPoisData =async ()=>{
        if(!user_id) return;
        try {
            const poisResponseObject = await getAllPoi(user_id);
            console.log(poisResponseObject);
            if (poisResponseObject !== 'error' && poisResponseObject != null) {
                const pois = implementPOIsObjectByCategory(poisResponseObject?.pois, poisResponseObject?.userHistory);
                const categories = filterCategoriesByExistingPois(pois, poisResponseObject?.categories,user_id);
                const categoryNameById = createCategoriesObjectByID(categories,user_id,language);
                
                if("savedTours" in poisResponseObject ){
                    setSavedTours(poisResponseObject.savedTours)
                }


                setPoisData({ pois, categories, categoryNameById });
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getPoisData();
    }, [user_id]);

    return { poisData,savedTours,setPoisData,getPoisData, loading, error };
};

export default usePoisAndCategories;
