import { serverHost} from '../redux/reducers/reducer';
import axios from 'axios';



export default async function savePersonalPoiRequest(p_userId,p_poi){
    const server =serverHost();
    try {
        const newPoiObject = await axios.post(server +'/passenger/save_personal_poi',{
            user_id: p_userId,
            poi: p_poi
        });
        return newPoiObject.data;
        
    } catch (error) {
        console.error('Error saving POI:', error);
        return null;
    }

}
