import { useEffect, useState } from 'react';
import { io } from "socket.io-client";
import { serverHost } from '../redux/reducers/reducer';



const UseSocket = () => {
  const serverUrl =serverHost()
  const [socket, setSocket] = useState(null);


useEffect(() => {
  const newSocket = io(serverUrl, {
    reconnectionDelay: 500,
    reconnectionDelayMax: 2000,
  });

  // Event handler for successful connection
 
  const onConnectEvent = () => {
 
  //   setTimeout(() => {
  //     if (newSocket.io.engine) {
  //       // close the low-level connection and trigger a reconnection
  //       newSocket.io.engine.close();
  //     }
  // }, 3000);
  }

  const onDisconnectEvent =(reason)=>{
    if (reason === "io server disconnect") {
      // the disconnection was initiated by the server, you need to reconnect manually
      socket.connect();
    }
  }

  newSocket.on('connect',onConnectEvent);
  newSocket.on('disconnect', onDisconnectEvent)


  setSocket(newSocket);

  return () => {
    newSocket.off('connect',onConnectEvent);
    newSocket.off('disconnect', onDisconnectEvent)
    newSocket.disconnect();
  };
}, [serverUrl]);



  return {socket };
};



export default UseSocket;