// Check if the user is inside the map boundaries 
const isUserInsideMapbounderies = (mapData,lat,long)=>{

    if(!mapData || !mapData.data) return false;
    
    let buttom_left =mapData.data[`map_coords_buttom_left`];
    let top_right = mapData.data[`map_coords_top_right`];
    
    if(long > buttom_left[1] && long < top_right[1] && lat > buttom_left[0] && lat < top_right[0]){
        return true;
    }
    else{
        return false;
    }

}

export default isUserInsideMapbounderies;