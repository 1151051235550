import React from 'react'
import { ReactComponent as Logo } from "../../assets/icons/carteavicon.svg"
import SvgIcon from '@mui/material/SvgIcon';


function Loading() {

  return (
    <div style={{
        display:'flex',
        flexDirection:'column',
        height: window.innerHeight,
        width: '100vw',
        justifyContent:'center',
        alignItems:'center',
        position:'absolute',
        top: 0,
        left:0
    }}
    >
        <div
        style={{ 
                fontSize: "25px", 
                color: '#009DDC', 
                fontFamily: 'Days one',
                fontWeight:'bold',
                position:'relative',
                bottom:'55px'
            }}
        >
            CARTEAV
        </div>
        <div>
            <SvgIcon style={{
                transform: 'scale(5.0)',
                }} 
            >
                <Logo />
            </SvgIcon>
        </div>
</div>
);
}

export default Loading;