import { serverHost} from '../redux/reducers/reducer';
import axios from 'axios';

export default async function GetMapData(){
    const server =serverHost();
    try {
        const response = await axios.get(server +'/utils/get_map_data',{
            params: {
                is_load_pics: false
            }
        });
        if(response.status === 200){
            return response.data
        }else{
            console.log("ERROR");
            return 'error';
        }
    } catch (error) {
        console.log("ERROR" ,error);
        return 'error';
    }

}

export async function GetMapImage(){
  
    const server =serverHost();
    try {
        const mapUrl = `${server}/images/defaultMapImage`;

        const response = await axios.get(mapUrl,{
            responseType: 'arraybuffer',
          });
       if(response.status === 200){
            const imageBlob = new Blob([response.data], { type: ['image/png','image/avif','image/jpeg'], });
            const imageURL = URL.createObjectURL(imageBlob);
            console.log(`Image URL: ${imageURL}`);
            return imageURL;
        }else{
            console.log("ERROR");
            return 'error';
        }
    } catch (error) {
        console.log("ERROR" ,error);
        return 'error';
    }

} 