import { AppText } from "../components/multiLanguage/multiLanguage";
import { serverHost } from "../redux/reducers/reducer";
import axios from "axios";
const { v4: uuidv4 } = require('uuid');

/**
 * This function performs pre-reservation checks before initiating a transportation reservation.
 * It ensures that the user has a valid reservation key and makes necessary checks with the server.
 */
const preReservationCheck =async ({
    setSecondaryTopBarMessageText,
    user_id,
    setShowUserInUseDialog,
    connectionError,
    language
})=>{

    let user_key_reservation = localStorage.getItem('user_key_reservation');
        if (user_key_reservation === null) {
            user_key_reservation = uuidv4();
            localStorage.setItem('user_key_reservation', user_key_reservation);
    }
    const sendObj = {
        user_id: user_id,
        userKeyReservation: user_key_reservation,
        useTimeout: true
    };

  try {
    const newReservationResponse = await  axios.post(serverHost() + `/transportation/preMissionCheck`, sendObj) ;
 

    if (!newReservationResponse || !newReservationResponse.data) {
      setSecondaryTopBarMessageText(AppText.userOrder.something_went_wrong[language] );
    //   console.log("preMissionCheck response is " ,newReservationResponse);
      return;
      }else if (newReservationResponse.data.is_user_not_exist){   
          setSecondaryTopBarMessageText(AppText.messages.user_invalid[language]);
          return;
      }else if (newReservationResponse.data.is_customer_res_count_limited){
        setSecondaryTopBarMessageText(AppText.messages.reservation_limit[language]);
        return;
      }else if (newReservationResponse.data.is_carts_not_valid){
        // setSecondaryTopBarMessageText(AppText.messages.cant_found_cart[language]);
        return;
      }else if (newReservationResponse.data.is_customer_time_expired){
        setSecondaryTopBarMessageText(AppText.messages.user_invalid[language]);
        return;
      }
     
      else if (newReservationResponse.data.is_current_user_in_use){
        localStorage.removeItem('user_key_reservation');
        setShowUserInUseDialog(true);
        return;
      }
    }catch(e){
        connectionError();
        return;
    }
}


export default preReservationCheck;