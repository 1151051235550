import extractPoiLocationFromString, { convertPoiLocationObjectToArray } from "./extractPoiLocationFromString";

const onPoiClick = async ({
        p_poi,
        isPickup,
        setChoosenPoi,
        setMapIconLabelText,
        stopWatchingUserLocationFunction,
        setUserCurrentLocation,
        setPickUpFromText,
        setShowPoi,
        triggerConfirmDestination,
    })=>{



        const location =extractPoiLocationFromString(p_poi)
        const poiLocationArray = convertPoiLocationObjectToArray(location)

        setMapIconLabelText(prevState => prevState !== "" ?"":prevState);

        if(isPickup){
            stopWatchingUserLocationFunction();      
            setUserCurrentLocation(location);
            setShowPoi(false);
            setPickUpFromText(p_poi.poi_name);
        }else{

            setChoosenPoi(p_poi)

            const response = await triggerConfirmDestination(poiLocationArray,p_poi.poi_name,setShowPoi);

            if(response === true){
                setShowPoi(false);
            }
            return response;
        }

}

export default onPoiClick;