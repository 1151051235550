
import { serverHost } from '../redux/reducers/reducer';
import axios from 'axios';

export default async function saveFeedbackRequest(p_userId, p_resevationId, p_rate, p_feedback) {
  const server = serverHost();

  try {
    const success = await axios.post(server + '/feedback/', {
      user_id: p_userId,
      reservation_id: p_resevationId,
      rate: p_rate,
      feedback_text: p_feedback,
    });
    
    return success.data; 
  } catch (error) {
    console.error('Error saving feedback:', error);
    throw error; 
  }
}
