import renderCustomAlert from "../../components/customAlert/customAlert";
import { AppText } from "../../components/multiLanguage/multiLanguage";


export function somethingWentWrongAlert(language){
    renderCustomAlert({
        id: 'something_went_wrong',
        title:AppText.alert.something_went_wrong[language],
        bodyText: AppText.alert.try_again[language],
        type: 'error',
        language: language,
        duration: 2000
    });
}

export function cartInPointAlert(language){
    renderCustomAlert({
        id: 'choose_new_destination',
        title: AppText.alert.already_in_point[language],
        bodyText: AppText.alert.choose_new_destination[language],
        type: 'info',
        language: language,
        duration: 2000
    });
}


export function cartNotFoundAlert(language){
    renderCustomAlert({
        id: 'cart_not_found',
        title: AppText.alert.we_sorry[language],
        bodyText: AppText.alert.cart_not_found[language],
        type: 'info',
        language: language,
        duration: 2000
    });
}
